<template>
  <div v-show="!isHidden" :style="'height:' + height + ';width:' + width + ';' + CSS">
    <div v-if="!isEditor()" v-html="localModel" class="ck-content" style="padding: 0 var(--ck-spacing-standard);"></div>
    <ckeditor v-else :editor="editor" style="height: 100%;" ref="editor" v-model="localModel"></ckeditor>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'

import CKEditor from '@ckeditor/ckeditor5-vue'
import InlineEditor from '@/components/HTMLEditor/ckeditor.js'

export default {
  components: {
    ckeditor: CKEditor.component
  },
  name: 'a-html',
  inject: {
    isEditor: {
      default: () => false
    },
    updateComponentProperty: {
      default: () => {}
    },
    getRawData: {
      default: () => () => {}
    }
  },
  mixins: [VisibleMixin, mixin],
  props: {
    guid: {
      type: String,
      frozen: true
    },
    height: {
      description: 'Высота',
      type: String
    },
    width: {
      description: 'Ширина',
      type: String
    },
    html: {
      type: String,
      default: 'HTML text',
      frozen: true
    }
  },
  watch: {
    localModel (value) {
      if (this.isEditor()) {
        this.updateComponentProperty(value, 'html', this.guid)
      }
    }
  },
  mounted () {
    if (this.isEditor()) {
      return false
    }
    this.localModel = this.getRenderedHTML()
  },
  methods: {
    getRenderedHTML () {
      let attributes = this.html.match(/\{{(.*?)\}}/g) || []
      let me = this
      let result = this.html
      attributes.forEach((attribute) => {
        attribute = attribute.replace('{{', '').replace('}}', '')
        let value = me.getRawData()[attribute]
        try {
          value = JSON.parse(value)
        } catch (e) {

        }
        if (value instanceof Array) {
          value = value.map(item => item.name).join(',')
        }
        result = result.replace(`{{${attribute}}}`, value || '')
      })

      return result
    }
  },
  data () {
    return {
      localModel: this.html,
      editor: InlineEditor
    }
  }
}
</script>

<style scoped src="../../CKEditor.css">

</style>
